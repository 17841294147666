<template>
    <div class="page-vip g-page">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ pageName }}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="page-wrap">
                <div class="page-side">
                    <div class="title" @click="showMenu = !showMenu">
                        <img src="@/assets/img/side-cart.png" alt="">
                        购物帮助
                    </div>
                    <div class="menu" :class="{show:showMenu}">
                        <div class="menu-item" v-for="(item,index) in menu" :key="index">
                            <div class="menu-item-tit"
                                 :class="{active:curPage === item.title}"
                                 @click="jumpTo(item)">
                                <h3>{{item.title}}</h3>
                                <p v-if="item.sub">{{item.sub}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page-content">
                    <router-view/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {articleShoppingHelpList} from "@/api/CMSmodule";
import Bus from "@/utils/bus";

export default {
    name: "index",
    data() {
        return {
            showMenu:false,
            curPage:'注册与登录',
            pageName:'',
            menu:[
                // {
                //     title:'注册与登录',
                //     path:'/help/registerAndLogin',
                // },
                // {
                //     title:'商品与订单',
                //     path:'/help/goodsAndOrder',
                // },
                // {
                //     title:'客户服务',
                //     sub:'(大宗询价、COA)',
                //     path:'/help/service',
                // },
                // {
                //     title:'交易条款',
                //     path:'/help/terms',
                // },
                // {
                //     title:'支付与发票',
                //     path:'/help/payAndBill',
                // },
            ]
        }
    },
	mounted() {
		console.log(this.$route.query) // 获取传递参数
		// this.renderCur(this.$route)
		Bus.$off('footer-botHelp') // 注销
		Bus.$on("footer-botHelp", (info) => { // 接收底部传输
			console.log(info)
			this.curPage = info.title
			Bus.$emit('shoppingHelp', info.id)
		})
		//
		if(this.$route.query.id){
			this.getarticleShoppingHelpList(this.$route.query.id) // 获取购物帮助列表
		}else{
			this.getarticleShoppingHelpList() // 获取购物帮助列表
		}
	},
	watch:{
		// '$route'(val){
		// 	this.renderCur(val)
		// }
	},
    methods:{
        jumpTo:function (data) {
        	console.log(data)
			// this.$router.push(data.path)
			this.curPage = data.title
			Bus.$emit('shoppingHelp', data.id)
			// this.$router.push({path:'/help/service',query:data.id})
        },
        renderCur:function (route) {
        	console.log(route)
            // this.pageName = route.meta.title
            // this.menu.map(item => {
            //     if (item.path === route.path){
            //         this.curPage = item.title
            //     }
            // })
        },

		//  -------------------------接口请求
		getarticleShoppingHelpList(id){ // 获取购物帮助列表
			articleShoppingHelpList({}).then((res) => {
				if (res.data.code==200) {
					this.menu=res.data.data.records
					console.log(id)
					if(id){
						this.menu.forEach(i=>{
							if(id==i.id){
								this.curPage = i.title
								Bus.$emit('shoppingHelp', id)
							}
						})
					}else{
						this.curPage = this.menu[0].title
						Bus.$emit('shoppingHelp', this.menu[0].id)
					}

				}else{

				}

			})
		},

    },

}
</script>

<style scoped>

</style>
